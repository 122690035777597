import React, {useState, useEffect, useCallback, useContext} from 'react';
import CustomInput from '../../custom-input';
import Info from './info';
import Result from './result';
import {toolRequest, request} from '../../../requests';
import {validURL, returnSocketLink, freeToolsErrors, ERROR_MESSAGES} from '../../../helpers/constants';
import ProgressWithHint from '../../progress-hint';
import GoToLoginPage from '../tools-login';
import LimitReached from '../limit-modal';
import {UserContext, UserType} from '../../../helpers/contexts/user-context';
import './styles.scss';
import {Link} from 'gatsby';
import {HexomaticUserType} from '../../../helpers/contexts/hexomatic-user';
import {useQuery} from '@apollo/react-hooks';
import {GET_HEXOMATIC_USER} from '../../../graphql/queries';
import {getHintPopupPerTool} from '../../../helpers';

const TechStackAnalyzerWrapper = () => {
  const hintName = 'free-tools-hint-tech-stack';
  const {user} = useContext(UserContext);

  const [value, setValue] = useState('');
  const [socket, setSocket] = useState<any>(null);
  const [socketId, setSocketId] = useState('');
  const [result, setResult] = useState([]);
  const [error, setError] = useState('');
  const [limit, setLimit] = useState('');
  const [loading, setLoading] = useState(false);
  const [limitReached, setLimitReached] = useState(false);
  const [show, setShow] = useState(false);
  const [showCreditsModal, setShowCreditsModal] = useState(false);
  const [clientId, setClientId] = useState('');
  const [mainUser, setUser] = useState<null | UserType>(null);
  const [hexomaticUser, setHexomaticUser] = useState<null | HexomaticUserType>(null);

  const {data: hexomaticUserData} = useQuery(GET_HEXOMATIC_USER, {
    fetchPolicy: 'no-cache',
    skip: !!getHintPopupPerTool(hintName),
  });
  //----------useEffect-------------------
  useEffect(() => {
    if (hexomaticUserData && hexomaticUserData.HexomaticUser && hexomaticUserData.HexomaticUser.getByLegacyId) {
      setHexomaticUser(hexomaticUserData.HexomaticUser.getByLegacyId);
    }
    if (hexomaticUserData && hexomaticUserData.User && hexomaticUserData.User.get) {
      setUser(hexomaticUserData.User.get);
    }
  }, [hexomaticUserData]);
  useEffect(() => {
    // if (localStorage.getItem('techStackSocketId')) {
    //   getResult();
    //   setSocketId(localStorage.getItem('techStackSocketId') || '');
    // }
    // if (localStorage.getItem('techStackSocketId') && localStorage.getItem('techStackURL'))
    //   setValue(localStorage.getItem('techStackURL') || '');
    socketStart();
  }, []);
  useEffect(() => {
    if (!socket) return;
    socket.onerror = function() {
      console.log('Connection Error');
    };

    socket.onopen = function() {
      console.log('WebSocket Client Connected');
    };
    socket.onclose = () => {
      socketStart();
    };
    return () => {
      socket.close();
    };
  }, [socket]);

  useEffect(() => {
    if (!socket) return;
    socket.onmessage = function(e: any) {
      const data = e ? JSON.parse(e.data) : '';
      if (data && data.clientId !== clientId) {
        setClientId(data.clientId);
        return;
      }
      if (data && data.error && freeToolsErrors.includes(data.error)) {
        setLimit(data.error);
        setShow(false);
        setLoading(false);
        setSocketId('');
        return;
      }
      if (data && data.socketId !== socketId) return;
      setResult(data._tech_stack.length > 0 ? data._tech_stack : ['Not found']);
      setShow(false);
      setSocketId('');
      // localStorage.removeItem('techStackSocketId');
      // localStorage.removeItem('techStackURL');
    };
  }, [socket, socketId, clientId]);
  //--------------------Functions---------------------------
  const socketStart = () => {
    //@ts-ignore
    const newSocket = new WebSocket(returnSocketLink());
    setSocket(newSocket);
  };
  const handleClick = useCallback(async () => {
    if (!value || !value.trim()) {
      return;
    }
    if (!validURL(value.trim())) {
      setError('URL is not valid');
      return;
    }
    setError('');
    setShow(false);
    if (!getHintPopupPerTool(hintName) && user && user.id) {
      setShowCreditsModal(true);
      return;
    }
    try {
      setLoading(true);
      const res = await toolRequest('tech-stack', value.trim(), {clientId, requestSource: 'free_tools'});
      if (res.status === 403) {
        setLimitReached(true);
        //setLoading(false);
        return;
      }
      if (res?.data?.socketId) {
        setSocketId(res.data.socketId);
        //localStorage.setItem('techStackSocketId', res.data.socketId);
        //localStorage.setItem('techStackURL', value.trim());
        setResult([]);
        setShow(true);
      } else if (res.errorData) {
        if (ERROR_MESSAGES[res.errorData.message]) {
          setLimit(ERROR_MESSAGES[res.errorData.message]);
        } else {
          setError('Something went wrong. Please try again.');
        }
      }
      setLoading(false);
    } catch (error) {
      setError('Something went wrong. Please try again.');
      setLoading(false);
    }
  }, [value, setError]);

  const handleChangeModalState = () => {
    setLimitReached(false);
  };

  const getResult = async () => {
    if (!socketId) return;
    try {
      //setLoading(true);
      const res: any = await request(`result/${socketId}`);
      if (
        res &&
        res.data &&
        res.data.result &&
        res.data.result.error &&
        freeToolsErrors.includes(res.data.result.error)
      ) {
        setLimit(res.data.result.error);
        setShow(false);
        //setLoading(false);
        setSocketId('');
        return;
      }
      if (res && res.data && res.data.result && res.data.result._tech_stack) {
        // localStorage.removeItem('techStackSocketId');
        // localStorage.removeItem('techStackURL');
        setResult(res.data.result._tech_stack.length > 0 ? res.data.result._tech_stack : ['Not found']);
        setShow(false);
      }
      //setLoading(false);
    } catch {
      //setLoading(false);
    }
  };
  //---------------------------------------------------------
  return (
    <div className="d-block m-auto tech-stack-wrapper">
      {
        <div className="d-flex pb-2" style={{maxWidth: '752px', margin: 'auto'}}>
          <Link className="n-wrap" to="/tools">
            ← Back to tools
          </Link>
          <Link to="/tools/history/#tech-stack" className="ml-auto n-wrap">
            See history →
          </Link>
        </div>
      }
      <CustomInput
        onClick={handleClick}
        onChange={setValue}
        value={value}
        placeholder="Add URL"
        btnName="Inspect"
        error={error}
        disabled={(loading && result.length < 1) || show}
      />
      {result && result.length > 0 && <Result result={result} />}
      <div className={`mx-auto col-12 my-1 ${socketId && result.length < 1 ? 'visible' : 'invisible'}`}>
        {show && <ProgressWithHint func={getResult} />}
      </div>
      <Info />
      {limitReached && <GoToLoginPage toolName="Tech Stack Analyzer" setOpen={handleChangeModalState} />}
      {limit && <LimitReached text={limit} setLimit={setLimit} />}
      {showCreditsModal && (
        <LimitReached
          hint_name={hintName}
          defaultModal
          hideModal={setShowCreditsModal}
          automation_credits={1}
          confirm={handleClick}
          user={mainUser}
          hexomaticUser={hexomaticUser}
        />
      )}
    </div>
  );
};

export default TechStackAnalyzerWrapper;
