import React from 'react';
import FAQItem from '../../../components/FAQ-item';
import Layout from '../../../components/layout/layout';
import {PageHero} from '../../../components/page-hero';
import Section from '../../../components/section';
import TechStackAnalyzerWrapper from '../../../components/tools-components/tech-stack-analyzer';

const TechStackAnalyzer = () => (
  <Layout>
    <PageHero
      title="Identify the tech stack used on any website"
      titleSmall
      subtitle="Find out what technologies are used by prospects or competitors powered by Hexomatic."
      className="toolPage"
      id='seo_home_area_tools'
    />
    <Section className="m-auto w-100">
      <TechStackAnalyzerWrapper />
    </Section>
    <Section>
      <div className="col-12 d-block mb_20 m-auto" style={{maxWidth: '800px', width: '100%'}}>
        <h4 className="mb-4 mt-4 text-center">Tech Stack Analyzer FAQ</h4>
        <FAQItem
          title="What is a tech stack?"
          text={
            <>
              <p>
                In the digital ecosystem, a tech stack (technology stack) is considered the list of technology services
                used for creating and running an application. For example, www.hexomatic.com is composed of a
                combination of React, core.js, etc.
              </p>
              <p>
                The tech stack is also associated with Martech or sales services, but, initially, it was developed in
                the software development community.
              </p>
              <p>
                Developers use a tech stack to quickly summarize the programming languages, frameworks, and tools needed
                to work with an application.
              </p>
            </>
          }
        />
        <FAQItem
          title="How to tell what platforms a website is built on?"
          text={
            <p>
              You can use the Hexomatic tech stack checker tool to tell what platforms a website is built on, for
              example is a website using WordPress or Shopify? Google Analytics or a Facebook tracking pixel? To do
              that, you just need to add the targeted website URL and click Start. This tool is ideal for the research
              of competitors’ websites.
            </p>
          }
        />
        <FAQItem
          title="How to audit the technology stack used on a website?"
          text={
            <p>
              Any website uses some kind of tech stack. The easiest way to find out what tech stack is used by a
              specific website is to use a tech stack checker to get insights on what it is built with, or what new apps
              your competitors are using.
            </p>
          }
        />
        <FAQItem
          title="Why is tech stack analysis important?"
          text={
            <>
              <p>Monitoring your website’s tech stack and that of your competitors is crucial.</p>
              <p>
                Monitoring your competitors’ tech stack allows being notified once a new CMS, ecommerce platform or any
                other technology is applied by any of your competitors. This will help you be ahead of competition and
                provide best-in-class solutions to your customers.
              </p>
            </>
          }
        />
        <FAQItem
          title="How to choose a tech stack for your project?"
          text={
            <>
              <p>
                A crucial part of starting a new project is carefully choosing the combination of software and hardware
                to use for building your product.
              </p>
              <p>
                A number of factors should be considered when choosing a tech stack, including compatibility, cost,
                speed, security, scalability, and more.
              </p>
              <p>
                By checking your competitors tech stack you can get an idea of which platforms and 3rd party plugins or
                services have been battle tested.
              </p>
            </>
          }
        />
      </div>
    </Section>
  </Layout>
);

export default TechStackAnalyzer;
