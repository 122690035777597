import {navigate} from 'gatsby-link';
import React from 'react';
import Button from '../../button';
import picture from '../../../images/tools/tech-stack-analysis/one.png';
import hexomaticGif from '../../../images/Hexomatic_Translation.gif';

const Info = () => (
  <div className="tech-stack-info row mt-0 mx-2">
    <div className="col-12 col-md-6 mb-5 p-2">
      <h4 className="mb-4">Automate time consuming tech stack and competitor analysis with Hexomatic</h4>
      <div className="mb-2">
        Hexomatic is a unique work automation platform that enables you to tap into 100+ ready made automations to create
        your perfect workflow.
      </div>
      <div className="mb-2">
        Discover the technologies running on any website, find contact details, perform audits and more on autopilot.
      </div>
      <div className="mb-4">
        Hexomatic provides cost effective competitor research at scale via a simple point and click interface.
      </div>
      <div>
        <Button onClick={() => navigate('/tools/')}>Click here to find out more</Button>
      </div>
    </div>
    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-5">
      <img src={hexomaticGif} alt="tech stack analysis" />
    </div>
    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-5 change-order-3">
      <img src={picture} alt="tech stack analysis" />
    </div>
    <div className="col-12 col-md-6 mb-5">
      <h4 className="mb-4">How does tech stack analysis work?</h4>
      <div className="mb-2 space-added">
        Our free tool detects 1000’s of different technologies used on any website including coding frameworks,
        analytics tracking services, tracking pixels, marketing tools, CMS or server stacks.
      </div>
      <h4 className="mb-4">Empower your sales and marketing teams with technology insights</h4>
      <div className="mb-2">
        Tech stack detection enables you to find out what websites are built with, what new apps your competitors are
        using and understand the needs of potential prospects.
      </div>
      <div className="mb-2">
        With Hexomatic you can instantly reveal the technology used and enrich the data with contact details, social
        media profiles, capture screenshots, perform SEO audits and more at scale like never before.
      </div>
    </div>
  </div>
);

export default Info;
